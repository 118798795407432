export default [
  {
    "id": "1",
    "menuUri": "/admin/index",
    "menuName": "首页",
    "parentId": null,
    "menuIcon": "el-icon-house",
    "showCrumb": false
  },
  {
    "id": "20",
    "menuUri": "/biz/goods",
    "menuName": "商品监控",
    "parentId": null,
    "menuIcon": "el-icon-goods",
    "showCrumb": true
  },
  {
    "id": "21",
    "menuUri": "/biz/apitoken",
    "menuName": "API授权管理",
    "parentId": null,
    "menuIcon": "el-icon-guide",
    "showCrumb": true
  },
  {
    "id": "22",
    "menuUri": "/biz/apple",
    "menuName": "Apple订单查询",
    "parentId": null,
    "menuIcon": "el-icon-search",
    "showCrumb": true
  },
  {
    "id": "23",
    "menuUri": "/biz/ticket",
    "menuName": "抢票",
    "parentId": null,
    "menuIcon": "el-icon-news",
    "showCrumb": true
  },
  {
    "id": "503",
    "menuUri": "/config/proxy",
    "menuName": "代理管理",
    "parentId": '5',
    "menuIcon": "el-icon-document",
    "showCrumb": true
  },
 
  {
    "id": "80",
    "menuUri": "/admin/manager",
    "menuName": "系统管理",
    "parentId": null,
    "menuIcon": "el-icon-setting",
    "showCrumb": true
  },
  {
    "id": "80000",
    "menuUri": "/admin/manager/settings",
    "menuName": "系统设置",
    "parentId": "80",
    "menuIcon": "el-icon-set-up",
    "showCrumb": true
  },
  {
    "id": "80001",
    "menuUri": "/admin/manager/role",
    "menuName": "角色管理",
    "parentId": "80",
    "menuIcon": "el-icon-price-tag",
    "showCrumb": true
  },
  {
    "id": "80002",
    "menuUri": "/admin/manager/user",
    "menuName": "管理员管理",
    "parentId": "80",
    "menuIcon": "el-icon-user",
    "showCrumb": true
  },
  {
    "id": "80003",
    "menuUri": "/admin/manager/logs",
    "menuName": "日志查看",
    "menuIcon": "el-icon-document-copy",
    "parentId": "80",
    "showCrumb": true
  }, {
    "id": "80004",
    "menuUri": "/dbtable",
    "menuName": "数据表管理",
    "parentId": "80",
    "menuIcon": "el-icon-coin",
    "showCrumb": true
  }
]
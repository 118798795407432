import { post, _axios } from "../util/http"
/**
 * 登录
 * @param  params 
 */
export const LoginRequest = params => post(`/user/login`, params)

/**
 * 修改密码
 * @param  params 
 */
export const UpdatePwd = params => post(`/user/pwd/update`, params)
/**
 * 发送验证码
 * @param  params 
 */
export const sendEcode = params => post(`/user/sendEcode`, params)
/**
 * 加载用户数量信息
 * @param  params 
 */
export const SelectUserNumber = params => post(`/user/selectUserNumber`, params)

/**
 * 注册
 * @param  params 
 */
export const RegisterRequest = params => post(`/user/register`, params)

/**
 * 登出
 * @param  params 
 */
export const LogoutRequest = params => post(`/user/logout`, params)

/**
 * 注册
 * @param  params 
 */
export const registerEcode = params => post(`/user/registerEcode`, params)

/**
 * 密码找回
 * @param  params 
 */
export const RegisterUppwd = params => post(`/user/uppwd`, params)
/**
 * 修改用户信息
 * @param  params 
 */
export const RegisterUpdateUser = params => post(`/user/update`, params)
/**
 * 查询用户列表
 * @param  params 
 */
export const SelectUserPage = params => post(`/user/selectPage`, params)
/**
 * 修改用户状态
 * @param  params 
 */
export const UpdateUserStatus = params => post(`/user/updateUserStatus`, params)

/**
 * 添加授权次数
 * @param  params 
 */
export const addAuthTimes = params => post(`/user/addAuthTimes`, params)

/**
 * 获取配置
 */
export const getSetting = params => post('/setting/get', params)

/**
 * 设置配置
 */
export const setSetting = params => post('/setting/save', params)

/**
 * 查询日志列表
 * @param  params 
 */
export const SelectLogsPage = params => post(`/logs/selectPage`, params)


/**
 * 保存角色
 * @param  params 
 */
export const SaveRole = params => post(`/manager/role/save`, params)

/**
 * 分页列表角色
 * @param  params 
 */
export const SelectPageRole = params => post(`/manager/role/selectPage`, params)

/**
 * 角色列表
 * @param  params 
 */
export const SelectListRole = params => post(`/manager/role/selectList`, params)

/**
 * 角色删除
 * @param  params 
 */
export const DeleteRole = params => post(`/manager/role/delete`, params)
/**
 * 查询角色拥有菜单
 * @param  params 
 */
export const GetRoleMenu = params => post(`/manager/role/getRoleMenu`, params)


/**
 * 查询配置
 * @param  params 
 */
export const SelectConfig = params => post(`/manager/config/get`, params)

/**
 * 保存配置
 * @param  params 
 */
export const SaveConfig = params => post(`/manager/config/save`, params)

/**
 * 保存配置
 * @param  params 
 */
export const SaveBizConfig = params => post(`/manager/config/saveBiz`, params)



/**
 * 文件查询列表
 * @param  params 
 */
export const selectFiles = params => post(`/file/selectList`, params)

/**
 * 删除文件
 * @param  params 
 */
export const DeleteFile = params => post(`/file/delete`, params)

/**
 * 授权 stockx
 * @param  params 
 */
export const authRequest = params => post(`/wechat/auth`, params)


/**
 * 上传附件
 *
 * @param {Object}
 * @return Result
 */
export const UploadFile = (params, callback) => _axios.post('/file/upload', params, {
  headers: { 'Content-Type': 'multipart/form-data' },
  onUploadProgress (progressEvent) {
    if(progressEvent.lengthComputable){
      //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
      //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
      callback(progressEvent);
    }
  }
})
<template>
  <!-- 递归菜单 @ldy -->
  <el-menu
    :default-active="defaultActive"
    class="el-menu"
    :collapse.sync="collapse"
    active-text-color="#1FA8A0"
    :unique-opened="true"
    @select="clickHandle"
  >
    <template v-for="(menu, i) in menus">
      <el-menu-item-group v-if="menu.isGroup" :key="i">
        <span slot="title" class="group-line"></span>
        <drp-menu-item :menu="menu"></drp-menu-item>
      </el-menu-item-group>
      <drp-menu-item v-else :key="i" :menu="menu"></drp-menu-item>
    </template>
  </el-menu>
</template>

<script>
import DRPMenuItem from './Item'
export default {
  props: {
    menus: {
      type: Array,
      default() {
        return []
      }
    },
    defaultActive: {
      type: String,
      default: ''
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'drp-menu-item': DRPMenuItem
  },
  methods: {
    clickHandle(a) {
      this.$event.$emit('click-menu')
      this.$router.push(a).catch(() => {})
    }
  }
}
</script>

<style>
.group-line {
  background-color: #f1f2f3;
  display: block;
  width: calc(100% - 20px);
  height: 1px;
  margin-right: 20px;
}
.el-menu:not(.el-menu--collapse) {
  width: 250px;
  text-align: left;
}
</style>

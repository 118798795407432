import Layout from '@/views/admin/common/layout'
// import test from '@formily/element-renderer'
// console.log(test)
/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    redirect: '/admin'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: { title: '登录', keepAlive: false, index: 0, anonymous: true }
  }, {
    path: '/lowcode',
    name: 'Lowcode',
    component: () => import('@/views/lowcode'),
    meta: { title: '低代码', keepAlive: false, index: 0, anonymous: true }
  }, {
    path: '/auth',
    name: 'Auth',
    props: (route) => ({...route.query}),
    component: () => import('@/views/auth'),
    meta: { title: '授权', keepAlive: false, index: 0, anonymous: true }
  }
]

/**
 * 自定义路由
 * @type { *[] }
 */
export const customRouterMap = [
  {
    path: '/admin', // 后台
    name: 'admin',
    component: Layout,
    // redirect: () => '/admin/index',
    redirect: '/admin/index',
    children: [
      {
        path: '/admin/index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/main'),
        meta: { title: '首页', keepAlive: false}
      },
      {
        path: '/config/proxy',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/proxy'),
        meta: { title: '代理配置', keepAlive: false }
      },
      {
        path: '/biz/goods',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/biz/goods'),
        meta: { title: '商品监控', keepAlive: false }
      },
      {
        path: '/biz/apitoken',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/biz/apitoken'),
        meta: { title: 'API授权管理', keepAlive: false }
      },
      {
        path: '/biz/apple',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/biz/apple'),
        meta: { title: 'Apple订单', keepAlive: false }
      },
      {
        path: '/biz/ticket',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/biz/ticket'),
        meta: { title: '抢票', keepAlive: false }
      },
      {
        path: '/admin/manager/user',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manager/user'),
        meta: { title: '管理员管理', keepAlive: false}
      },
      {
        path: '/admin/manager/role',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manager/role'),
        meta: { title: '角色管理', keepAlive: false}
      },
     
      {
        path: '/admin/manager/logs',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manager/logs'),
        meta: { title: '日志查看', keepAlive: false }
      },
       {
        path: '/docs-api',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/docs'),
        meta: { title: '接口文档', keepAlive: false }
      }, {
        path: '/admin/manager/settings',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manager/settings'),
        meta: { title: '系统设置', keepAlive: false}
      },  {
        path: '/dbtable',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/dbtable'),
        meta: { title: '数据表管理', keepAlive: false }
      },
     ]
  },
  {
    path: '/magic',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/magic'),
    meta: { title: '接口管理', keepAlive: false,  anonymous: true }
  },
  {
    path: '*',
    component: () => import('@/views/admin/common/404'),
    meta: { title: '找不到页面', keepAlive: false, anonymous: true}
  }
]

